<template>
    <!-- PC端金叶子提现，扫码充值 -->
    <div class="main">
        <div class="isNotweixin" v-if="isnotweixin">
            <div class="isNotweixin-box">
                <img src="./wxnot.jpg" alt="">
                <span>仅限微信浏览器访问，请在微信客户端打开链接</span>
            </div>
        </div>
        <!--  <div>获取的code是：==》{{ code || '--' }}《==</div>
        <div>是微信内置浏览器吗？{{ iswx }}</div>
        <div>id？{{ id || '--' }}</div>
        <div>amount{{ amount || '--' }}</div>
        <div>payInfo{{ payInfo }}</div>
        <div>回调的地址{{ nowUrl || '--' }}</div> -->
        <div v-if="!isnotweixin" class="top">
            <p class="topHead">
                <span>{{ isFinished ? '已支付订单' : '支付订单' }}</span>
            </p>
            <div class="money">
                <p class="moneyP">
                    <span>￥<span class="moneyP-span">{{ amount ? Number(amount).toFixed(2) : '' }}</span></span>
                </p>
                <div class="moneyMain">
                    <div class="moneyMain-one">
                        <img src="./wxin.png" alt="">
                        <span>微信支付</span>
                    </div>
                    <div class="moneyMain-two"><img src="./wxicon.png" alt=""></div>
                </div>
                <div class="payFor-foot">
                    <van-button class="btn" :disabled="isDisabled" @click="payNow" block color="#E54320" round
                        type="info"><van-loading color="#1989fa" v-show="isLoading" /> <span v-show="!isLoading">{{
                            isFinished ? '已支付' : '立即支付' }}</span></van-button>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import {
    sendCode, sendCodeCharge, sendCodeOnline, returnCodeG, returnCodeH, returnCodeDP, payVideoAd, payGoodsAd, payIndexAd, payGoodsOffShop
} from "@/api/payRedRiceCode/index.js";
import { Toast } from "vant";

// 引入axios库
// import axios from 'axios';
export default {
    data() {
        return {
            giveCodeShow: true,
            isLoading: false,
            isDisabled: true,//是否禁用支付按钮
            isnotweixin: false,
            isFinished: false,//是否支付成功
            code: '',//获取code
            id: '',//订单id
            type: '',//区分订单来源 0是售后充值 ，1是线下店红米提现充值 ,2是线上充值，3是开店费,4短视频购买，5轮播图购买，6商品位购买,7线下店进货
            amount: '',//支付金额
            withdrawType: '',//线上充值给后台回调 4-兑换商城，5-福利商城，6-云店商城
            payInfo: {}//后端返回的订单信息
            // iswx: false,
            // nowUrl: '',//回调地址，

        };
    },

    created() {
        if (this.$route.query.id) {
            this.id = this.$route.query.id
            window.localStorage.setItem('orderId', this.$route.query.id)
            // window.localStorage.setItem("orderId", this.$route.query.id),
        } else {
            this.id = window.localStorage.getItem('orderId') || ''
        }
        if (this.$route.query.withdrawType) {
            this.withdrawType = this.$route.query.withdrawType
            window.localStorage.setItem('withdrawType', this.$route.query.withdrawType)
            // window.localStorage.setItem("orderId", this.$route.query.id),
        } else {
            this.withdrawType = window.localStorage.getItem('withdrawType') || ''
        }
        if (this.$route.query.type) {
            this.type = this.$route.query.type
            window.localStorage.setItem('orderType', this.$route.query.type)
            // window.localStorage.setItem("orderId", this.$route.query.id),
        } else {
            this.type = window.localStorage.getItem('orderType') || ''
        }
        if (this.$route.query.amount) {
            this.amount = Number(this.$route.query.amount).toFixed(2)
            window.localStorage.setItem('orderAmount', this.$route.query.amount)
        } else {
            this.amount = window.localStorage.getItem('orderAmount') || '---'
        }
        // this.nowUrl = location.href;
    },
    mounted() {
        let screenWidth = document.documentElement.getBoundingClientRect().width;
        document.documentElement.style.fontSize = screenWidth / 10 + "px";
        if (!this.isWeixinBrowser()) {
            this.isnotweixin = true;
        } else {
            this.getCode()
        }
        // this.iswx = this.isWeixinBrowser()
        let origin = window.location.protocol + "//" + window.location.host + "" + window.location.pathname + '#/payRedRiceCode';
        console.log(origin, '地址');
    },
    destroyed() {
        // window.removeEventListener("popstate", this.goBack, false);
        window.localStorage.clear()
    },
    methods: {
        //判断是否为微信浏览器
        isWeixinBrowser() {
            let ua = navigator.userAgent.toLowerCase();
            let rule = /micromessenger/
            return rule.test(ua) ? true : false;
        },
        //微信公众号静默授权
        getCode() {
            // 非静默授权，第一次有弹框
            // let origin = location.href;
            let origin = window.location.protocol + "//" + window.location.host + "" + window.location.pathname + '#/payRedRiceCode';
            let urlNow = encodeURIComponent(origin);
            var appid = 'wx97b62091844dbc89';
            this.code = this.getUrlCode().code; // 截取code
            if (this.code == null || this.code === '') {
                // 如果没有code，则去请求
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${urlNow}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
            } else {
                // 当code不等于空时，调用后端接口获取用户信息
                // 你自己的业务逻辑，把code给后台，然后拿payInfo调微信支付控件
                if (this.type == '0') {
                    sendCodeCharge({ code: this.code, id: this.id }).then(res => {
                        console.log(res, '<===获取信息');
                        if (res.data.code == 0) {
                            this.payInfo = JSON.parse(res.data.data)
                            this.isDisabled = false
                        } else {
                            Toast(res.data.message)
                        }
                    }).catch(() => {
                        Toast.fail('订单获取失败，请稍后再试')
                    })
                } else if (this.type == '1') {
                    sendCode({ code: this.code, id: this.id }).then(res => {
                        console.log(res, '<===获取信息');
                        if (res.data.code == 0) {
                            this.payInfo = JSON.parse(res.data.data)
                            this.isDisabled = false
                        } else {
                            Toast(res.data.message)
                        }
                    }).catch(() => {
                        Toast.fail('订单获取失败，请稍后再试')
                    })
                } else if (this.type == '2') {
                    sendCodeOnline({ code: this.code, id: this.id }).then(res => {
                        console.log(res, '<===获取信息');
                        if (res.data.code == 0) {
                            this.payInfo = JSON.parse(res.data.data)
                            this.isDisabled = false
                        } else {
                            Toast(res.data.message)
                        }
                    }).catch(() => {
                        Toast.fail('订单获取失败，请稍后再试')
                    })
                } else if (this.type == '3') {
                    returnCodeDP({ code: this.code, id: this.id }).then(res => {
                        console.log(res, '<===获取信息');
                        if (res.data.code == 0) {
                            this.payInfo = JSON.parse(res.data.data)
                            this.isDisabled = false
                        } else {
                            Toast(res.data.message)
                        }
                    }).catch(() => {
                        Toast.fail('订单获取失败，请稍后再试')
                    })
                } else if (this.type == '4') {
                    payVideoAd({ code: this.code, id: this.id }).then(res => {
                        console.log(res, '<===获取信息');
                        if (res.data.code == 0) {
                            this.payInfo = JSON.parse(res.data.data)
                            this.isDisabled = false
                        } else {
                            Toast(res.data.message)
                        }
                    }).catch(() => {
                        Toast.fail('订单获取失败，请稍后再试')
                    })
                } else if (this.type == '5') {
                    payIndexAd({ code: this.code, id: this.id }).then(res => {
                        console.log(res, '<===获取信息');
                        if (res.data.code == 0) {
                            this.payInfo = JSON.parse(res.data.data)
                            this.isDisabled = false
                        } else {
                            Toast(res.data.message)
                        }
                    }).catch(() => {
                        Toast.fail('订单获取失败，请稍后再试')
                    })
                } else if (this.type == '6') {
                    payGoodsAd({ code: this.code, id: this.id }).then(res => {
                        console.log(res, '<===获取信息');
                        if (res.data.code == 0) {
                            this.payInfo = JSON.parse(res.data.data)
                            this.isDisabled = false
                        } else {
                            Toast(res.data.message)
                        }
                    }).catch(() => {
                        Toast.fail('订单获取失败，请稍后再试')
                    })
                } else if (this.type == '7') {
                    payGoodsOffShop({ code: this.code, id: this.id }).then(res => {
                        console.log(res, '<===获取信息');
                        if (res.data.code == 0) {
                            this.payInfo = JSON.parse(res.data.data)
                            this.isDisabled = false
                        } else {
                            Toast(res.data.message)
                        }
                    }).catch(() => {
                        Toast.fail('订单获取失败，请稍后再试')
                    })
                }
            }
        },
        // 从url中获取code返回
        getUrlCode() {
            // 截取url中的code方法
            var url = location.search;
            this.winUrl = url;
            var theRequest = new Object();
            if (url.indexOf('?') != -1) {
                var str = url.substr(1);
                var strs = str.split('&');
                for (var i = 0; i < strs.length; i++) {
                    theRequest[strs[i].split('=')[0]] = strs[i].split('=')[1];
                }
            }
            return theRequest;
        },
        //调取微信支付
        payNow() {
            // this.isLoading = true
            this.isDisabled = true
            this.wxpay(this.payInfo)
        },
        //微信支付方法，传后台返回的pay_info
        wxpay(data) {
            var vm = this;
            //下面是解决WeixinJSBridge is not defined 报错的方法
            if (typeof WeixinJSBridge == "undefined") {//微信浏览器内置对象。参考微信官方文档
                if (document.addEventListener) {
                    document.addEventListener('WeixinJSBridgeReady', vm.onBridgeReady(data), false);
                } else if (document.attachEvent) {
                    document.attachEvent('WeixinJSBridgeReady', vm.onBridgeReady(data));
                    document.attachEvent('onWeixinJSBridgeReady', vm.onBridgeReady(data));
                }
            } else {
                vm.onBridgeReady(data);
            }

        },
        //使用微信浏览器私有api WeixinJSBridge 调起微信内支付
        onBridgeReady: function (data) {
            // eslint-disable-next-line no-undef
            WeixinJSBridge.invoke(
                'getBrandWCPayRequest', {//下面参数内容都是后台返回的
                debug: true,
                "appId": data.appId,//公众号名称，由商户传入
                "timeStamp": data.timeStamp,//时间戳
                "nonceStr": data.nonceStr,//随机串
                "package": data.package,//预支付id
                "signType": data.signType,//微信签名方式
                "paySign": data.paySign,//微信签名
            },
                function (res) {
                    // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                        this.isDisabled = true
                        this.isFinished = true
                        Toast.success('支付成功，请在pc端确认')
                        if (this.withdrawType) {
                            if (this.withdrawType == '4') {
                                returnCodeG({ id: this.id }).then(res => {
                                    if (res.data.code == 0) {
                                        Toast.success('支付回调成功，请在pc端确认')
                                        // eslint-disable-next-line no-undef
                                        WeixinJSBridge.call('closeWindow')
                                    } else {
                                        Toast.fail(res.data.message)

                                    }
                                }).catch(() => {
                                    Toast.fail('支付通知失败')
                                })
                            } else {
                                returnCodeH({ id: this.id }).then(res => {
                                    if (res.data.code == 0) {
                                        // eslint-disable-next-line no-undef
                                        WeixinJSBridge.call('closeWindow')
                                        Toast.success('支付回调成功，请在pc端确认')
                                    } else {
                                        Toast.fail(res.data.message)

                                    }
                                }).catch(() => {
                                    Toast.fail('支付通知失败')
                                })
                            }

                        } else {
                            // eslint-disable-next-line no-undef
                            WeixinJSBridge.call('closeWindow')
                        }
                    } else {
                        this.isDisabled = false
                        this.code = ''
                        this.getCode()
                        // this.isLoading = false
                        alert("支付失败,请联系管理员" + res.err_msg);
                    }
                }
            );
        },
    }
}
</script>
  
<style lang="less" scoped>
.main {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    font-size: 12px;

    .top {
        // width: 375px;
        padding: 20px 20px;
        height: 6rem;
        background: linear-gradient(279deg, #FF8273 0%, #E54320 100%);
        position: relative;

        border-bottom-left-radius: 0.6rem;
        border-bottom-right-radius: 0.6rem;

        .topHead {
            margin-top: 54px;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                font-size: 17px;
                color: #FFFFFF;
            }
        }

        .money {
            width: 80%;
            height: 8rem;
            padding: 0.4rem;
            border-radius: 0.3rem;
            // border: 1px solid #FF8273;
            background: #ffffff;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, 0%);

            .moneyP {
                width: 90%;
                height: 60%;
                color: #FF456C;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 21px;

                .moneyP-span {
                    font-size: 38px;

                }
            }

            .moneyMain {
                width: 100%;
                padding: 10px;
                margin-bottom: 1.6rem;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .moneyMain-one {
                    display: flex;
                    align-items: center;
                    font-size: 14px;

                    img {
                        width: 0.5rem;
                        height: 0.5rem;
                        margin-right: 0.5rem;
                    }
                }

                .moneyMain-two {
                    display: flex;
                    align-items: center;

                    img {
                        width: 0.5rem;
                        height: 0.5rem;
                    }
                }
            }

            .payFor-foot {
                .btn {
                    width: 100%;
                }
            }
        }
    }

    .isNotweixin {
        .isNotweixin-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 14px;
        }
    }
}
</style>
  