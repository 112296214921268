import https from '@/router/https'
//线下店红米充值微信支付
export function sendCode(param) {
    return https.fetchGet('/mall/wechatCheck/redirectUrl',
        param);
}
//商家售后充值微信支付
export function sendCodeCharge(param) {
    return https.fetchGet('/mall/wechatCheck/redirectUrlItem',
        param);
}
//商家售后充值微信支付
export function sendCodeOnline(param) {
    return https.fetchGet('/mall/withdrawalrecord/redirectUrl',
        param);
}
//线上充值支付成功回调鬼市
export function returnCodeG(data) {
    return https.fetchPost('/mall/withdrawalrecord/HFWithdrawalRice',
        data);
}
//线上充值支付成功回调红尘坊/侠客集市
export function returnCodeH(data) {
    return https.fetchPost('/mall/withdrawalrecord/HFWithdrawalRedRice',
        data);
}
//店铺端充值支付
export function returnCodeDP(params) {
    return https.fetchGet('/mall/wechatCheck/redirectUrlFree',
        params);
}
//店铺端短视频广告位支付
export function payVideoAd(params) {
    return https.fetchGet('/mall/wechatCheck/redirectUrlAdvertisingVideo',
        params);
}
//店铺端商品位广告支付
export function payGoodsAd(params) {
    return https.fetchGet('/mall/wechatCheck/recommendedColumn',
        params);
}
//店铺端轮播图广告支付
export function payIndexAd(params) {
    return https.fetchGet('/mall/wechatCheck/redirectUrlFreeCash',
        params);
}
//线下店铺端进货支付
export function payGoodsOffShop(params) {
    return https.fetchGet('mall/orderinfo/generateOrderAdaPay',
        params);
}